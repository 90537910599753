(function ($, site) {
  Drupal.behaviors.basicCarouselFormatterV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var self = this;
      var $formatter = $('.js-basic-carousel-formatter-v1', context);
      var $carousel;
      var defaultSlideDisplayInterval = 6000;
      var defaultSlideTransitionTime = 300;

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var thisFormatter = $(this);
        var carouselControlsNextLabel =
          site.translations.elc_general && site.translations.elc_general.next
            ? site.translations.elc_general.next
            : 'next';
        var carouselControlsPreviousLabel =
          site.translations.elc_general && site.translations.elc_general.previous
            ? site.translations.elc_general.previous
            : 'previous';
        var $currentCarousel = thisFormatter.find('.js-basic-carousel');
        var arrowsDiv = thisFormatter.find('.carousel-controls');
        var dotsDiv = thisFormatter.find('.carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';
        var $slickCounter = thisFormatter.find('.js-basic-carousel-formatter-counter');
        var $prevArrow =
          '<div class="slick-prev-button slick--custom"><svg role="img" aria-label="' +
          carouselControlsPreviousLabel +
          '" class="icon icon--caret--left slick-prev"><use xlink:href="#caret--left"></use></svg></div>';
        var $nextArrow =
          '<div class="slick-next-button slick--custom"><svg role="img" aria-label="' +
          carouselControlsNextLabel +
          '" class="icon icon--caret--right slick-next"><use xlink:href="#caret--right"></use></svg></div>';
        var useResponsive = false;

        $carousel = $currentCarousel;
        // Autoplay settings
        var autoplay = $carousel.data('slides-autoplay') || false;
        // Slides to show
        var slidesToShow = parseInt($currentCarousel.data('slides-show'));
        var slideDisplayInterval = parseInt($currentCarousel.data('slides-speed'));
        var slideTransitionTime = parseInt($currentCarousel.data('slides-speedplay'));

        if (isNaN(slidesToShow)) {
          slidesToShow = 1;
        } else if (slidesToShow > 3) {
          useResponsive = true;
        }
        if (isNaN(slideDisplayInterval)) {
          slideDisplayInterval = defaultSlideDisplayInterval;
        }
        if (isNaN(slideTransitionTime)) {
          slideTransitionTime = defaultSlideTransitionTime;
        }

        // slides to scroll
        var slidesToScroll = parseInt($currentCarousel.data('slides-scroll'));

        if (isNaN(slidesToScroll)) {
          slidesToScroll = 1;
        }

        // Responsive dots / arrows
        var pcDotsArrowsArgs = self.checkDotsAndArrows($currentCarousel.data('dots-arrows-pc'));
        var mobileDotsArrowsArgs = self.checkDotsAndArrows($currentCarousel.data('dots-arrows-mobile'));
        // plain with defaults
        var settingsPlain = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          autoplaySpeed: slideDisplayInterval,
          speed: slideTransitionTime
        };
        // case for custom slides count and dots for mobile
        var settingsResponsive = {
          appendArrows: arrowsDiv,
          slide: basicSlide,
          infinite: true,
          slidesToShow: slidesToShow,
          slidesToScroll: slidesToScroll,
          autoplay: autoplay,
          autoplaySpeed: slideDisplayInterval,
          speed: slideTransitionTime,
          dots: pcDotsArrowsArgs.dots,
          arrows: pcDotsArrowsArgs.arrows,
          appendDots: dotsDiv,
          prevArrow: $prevArrow,
          nextArrow: $nextArrow,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                dots: mobileDotsArrowsArgs.dots,
                arrows: mobileDotsArrowsArgs.arrows,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        // Init this carousel with our settings
        if (useResponsive === 'true') {
          $carousel.not('.slick-initialized').slick(settingsPlain);
        } else {
          $carousel
            .not('.slick-initialized')
            .on('init', function (event, slick) {
              self.renderSlickCounter(slick, $slickCounter);
            })
            .on('afterChange', function (event, slick) {
              self.renderSlickCounter(slick, $slickCounter);
            })
            .slick(settingsResponsive);
        }
      });
    },
    checkDotsAndArrows: function (dotsAndArrows) {
      var dotsArrowsArgs = {};

      switch (dotsAndArrows) {
        case 'arrows':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = true;
          break;
        case 'dots':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = false;
          break;
        case 'both':
          dotsArrowsArgs.dots = true;
          dotsArrowsArgs.arrows = true;
          break;
        case 'none':
          dotsArrowsArgs.dots = false;
          dotsArrowsArgs.arrows = false;
          break;
      }

      return dotsArrowsArgs;
    },
    renderSlickCounter: function (slick, $slickCounter) {
      var currentSlide = slick.currentSlide + 1;

      $slickCounter.html(currentSlide + '/' + slick.slideCount);
    }
  };
})(jQuery, window.site || {});
